<template>
  <ul>
    <li>Exclusion des aliments contenant de la chair animale (viande rouge, blanche, poisson, fruits de mer)</li>
    <li>Exclusion des aliments contenant des produits d'origine animale (oeuf, lait, miel)</li>
    <li>Contrôle de votre équilibre sur 20 nutriments pour éviter les carences</li>
  </ul>
</template>

<script>
export default {
  name: "VeganSummary",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

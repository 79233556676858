<template>
  <ul>
    <li>Les bons apports en sels</li>
    <li>Maîtrise des apports caloriques</li>
  </ul>
</template>

<script>
export default {
  name: "HypertensionSummary",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

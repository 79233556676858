<template>
  <h2 class="dialog-title" id="conditions">Conditions générales</h2>

  <div class="dialog-body">
    <h3>PREAMBULE</h3>
    <div>
      <p>
        Le site www.cookandbe.com (ci-après le « Site ») propose à ses utilisateurs non professionnels, sous réserve de
        leur inscription préalable dans les conditions de l’article 2, un accès personnalisé à différents services
        dédiés à la nutrition (ci-après les « Services ») :
      </p>
      <ul>
        <li>idées repas,</li>
        <li>liste de courses,</li>
      </ul>
    </div>
    <p>
      La plupart des Services sont accessibles à titre gratuit, sur inscription elle-même gratuite ; néanmoins, des
      Services optionnels sont dispensés à titre onéreux et sont exclusivement réservés aux utilisateurs ayant choisi de
      souscrire ce type d’abonnement et d’accepter les conditions générales de vente y afférentes.
    </p>

    <h3>ARTICLE 1 – Champ d’application</h3>

    <p>
      <b>1.1 - </b>
      Les présentes conditions générales d’utilisation (ci-après les « CGU ») ont vocation à régir les rapports entre
      CookandBe et tout utilisateur du Site non professionnel, tel qu’identifié lors de son inscription (ci-après l’ «
      Utilisateur »). En accédant au Site et en s’y inscrivant préalablement à son utilisation selon les modalités
      prévues à l’article 2, l’Utilisateur reconnait avoir pris connaissance des présentes CGU et accepter de s’y
      conformer sans aucune réserve en cochant la case prévue à cet effet au moment de son inscription. Les CGU
      constituent par conséquent un accord contractuel entre CookandBe et l’Utilisateur.
    </p>
    <p>
      En cas de modifications ultérieures des CGU, l’Utilisateur sera invité, lors de sa première connexion après que
      les nouvelles CGU aient été mises en ligne, à les accepter de manière expresse. Si l’Utilisateur refuse de se
      conformer à l’une quelconque des obligations et conditions des CGU, il est invité à ne pas accéder au Site et à ne
      pas l’utiliser.
    </p>

    <p>
      <b>1.2 -</b> Outre les CGU, les conditions générales de vente décrites à l’article 5 (ci-après les « CGV »)
      s’appliquent, sans restriction ni réserve à tout abonnement par l’Utilisateur aux Services payants disponibles sur
      le Site (ci-après l’ « Abonnement »), ce à l’exclusion de tout autre document en contradiction avec les CGV.
      L’Utilisateur revêtira à ce titre la qualité d’ « Abonné ».
    </p>
    <p>
      L’Abonné déclare avoir pris connaissance des CGU et CGV et les avoir acceptées de manière expresse et sans réserve
      en cochant la case prévue à cet effet avant la mise en œuvre de la procédure d’Abonnement en ligne.
    </p>
    <p>
      L’Abonné est informé que CookandBe se réserve le droit d’adapter ou de modifier à tout moment les CGU et CGV. Les
      CGU et CGV applicables seront celles en vigueur au jour de la souscription à l’Abonnement.
    </p>

    <p>
      <b>1.3 -</b> Les CGU et CGV en vigueur sont consultables à tout moment sur le Site à l’adresse
      https://www.cookandbe.com/legal
    </p>

    <p>
      <b>1.4 -</b> La nullité de l’une quelconque des clauses des présentes CGU et CGV, pour quelque motif que ce soit,
      n’affectera en rien la validité des autres clauses des CGU et CGV.
    </p>
    <p>
      Le fait que CookandBe ne se prévale pas, à un moment donné de l’une quelconque des présentes CGU et CGV, ne peut
      être interprété comme valant renonciation à se prévaloir ultérieurement de l’une quelconque desdites conditions.
    </p>

    <h3>ARTICLE 2 – Conditions et Modalités d’inscription</h3>

    <p><b>2.1 - Qualité d'Utilisateur</b></p>
    <p>
      Pour devenir Utilisateur, l’internaute personne physique non professionnelle doit avoir atteint l’âge de la
      majorité légale, ne pas faire l’objet d’une quelconque mesure de protection et avoir accepté les CGU sans aucune
      réserve.
    </p>

    <p><b>2.2 - Inscription préalable et obligatoire</b></p>
    <div>
      <p>
        Aux fins d’accéder aux Services disponibles sur le Site, l’Utilisateur devra au préalable s’inscrire en créant
        un compte utilisateur. A cette fin, l’Utilisateur devra fournir :
      </p>

      <ul>
        <li>son prénom,</li>
        <li>son âge / date de naissance,</li>
        <li>son adresse électronique,</li>
        <li>un mot de passe,</li>
      </ul>
    </div>
    <p>
      Un email sera alors automatiquement envoyé à l’adresse électronique de l’Utilisateur, qui permettra de confirmer
      la validité de l'adresse. Sans validation par l'Utilisateur sous 30 jours, CookAndBe se réserve le droit de
      supprimer le compte et de mettre un terme à la relation contractuelle
    </p>

    <p>
      L’Utilisateur peut également s’inscrire en se connectant à son compte ouvert auprès de certains sites tiers de
      réseaux sociaux (y compris, notamment, Facebook - ci-après, individuellement, un « Compte Tiers »). Les
      fonctionnalités du Site permettent de lier son Compte CookandBe à des Comptes Tiers.<br />
      L’Utilisateur déclare être autorisé à divulguer les informations de connexion de son Compte Tiers à CookandBe
      et/ou à donner à CookandBe un accès à son Compte Tiers (y compris, notamment, aux fins décrites aux présentes),
      sans manquer aux conditions qui régissent son utilisation du Compte Tiers en question, sans engager CookandBe à
      payer des frais et sans soumettre CookandBe à toute restriction d’utilisation imposée par ce fournisseur de
      services tiers.
    </p>

    <p>
      L’Utilisateur garantit à CookandBe fournir lors de son inscription des informations véridiques, à jour et
      complètes concernant son identité. Il s’engage notamment à fournir une adresse électronique effective et dont il
      est propriétaire.
    </p>

    <p>
      L’inscription sur le Site est entièrement gratuite. Il est précisé que tous les frais afférents aux matériels,
      logiciels et abonnements Internet nécessaires à l’accès et/ou l’utilisation du Site demeurent exclusivement à la
      charge de l’Utilisateur.
    </p>

    <p>
      L’Utilisateur est, par ailleurs, seul responsable du bon fonctionnement de son équipement informatique ainsi que
      de sa connexion à Internet.
    </p>

    <h3>ARTICLE 3 – Compte utilisateur et identifiants</h3>

    <p>
      L’Utilisateur ne pourra accéder aux Services qu’en se connectant à son compte utilisateur à l’aide de l’adresse
      électronique et du mot de passe fournis lors de son inscription ou via les réseaux sociaux.
    </p>
    <p>
      Toute connexion, utilisation et/ou transmission de données effectuées via un compte utilisateur est présumée avoir
      été effectuée par le titulaire dudit compte et sous sa seule et entière responsabilité.
    </p>
    <p>
      Les identifiants de l’Utilisateur (adresse électronique et mot de passe) sont strictement personnels et
      confidentiels. L’Utilisateur est seul et entièrement responsable de l’usage qui pourrait être fait de ses
      identifiants, et seul garant de leur confidentialité.
    </p>
    <p>
      Par conséquent, CookandBe ne pourra être tenue responsable des conséquences dommageables liées à la perte, au vol
      ou à la divulgation des identifiants de l’Utilisateur.
    </p>
    <p>
      En cas de perte ou de vol de ses identifiants, l’Utilisateur doit utiliser, dans les plus brefs délais, la
      procédure lui permettant de les modifier sur le Site.
    </p>

    <h3>ARTICLE 4 – Recettes et autres contributions des Utilisateurs</h3>

    <p>
      <b>4.1 -</b> L’Utilisateur a la possibilité de saisir des recettes de cuisine sur le Site dans la rubrique «
      Recettes ».
    </p>
    <p>
      Dans l’hypothèse où l’Utilisateur serait titulaire de droits d’auteur sur la recette, l’Utilisateur reconnait
      qu’en la saisissant sur Cook&Be, il accepte de céder à titre gratuit et exclusif à CookandBe l’ensemble des droits
      d’auteur patrimoniaux attachés à la recette, à savoir le droit de la reproduire et de la représenter, ce sur le
      Site ou tout autre support.
    </p>

    <p>
      Les droits ainsi cédés comprennent notamment, sans que cette liste soit limitative, les droits de diffusion,
      d’exploitation, d’adaptation, de modification, de commercialisation à titre onéreux.
    </p>

    <p>
      La cession est effective tant pour la France que pour l’étranger et pour toute la durée légale de protection de la
      recette par les droits d’auteur en France.
    </p>

    <p>
      <b>4.3 -</b> En sa qualité d’éditeur du Site, CookandBe se réserve le droit de refuser de partager sur le Site
      toute contribution qui contreviendrait aux réglementations en vigueur et/ou à la ligne éditoriale du Site.
    </p>
    <div>
      <p>
        De façon générale, CookandBe veille au respect par l’Utilisateur des règles élémentaires d’expression publique.
        Les contributions de l’Utilisateur (recettes, commentaires, questions/réponses...) sont donc susceptibles d’être
        modérées par CookandBe. Ceci s’applique notamment mais non exclusivement à :
      </p>
      <ul>
        <li>
          tout propos contraire à la loi ou la réglementation en vigueur (incitation à la haine raciale, racisme, appels
          à la violence, xénophobie, révisionnisme, négationnisme, atteinte à la vie privée, au droit de propriété
          intellectuelle, etc.) ;
        </li>
        <li>tout propos grossier, obscène ou à caractère pornographique ;</li>
        <li>
          tout propos agressif, violent, insultant ; qu’il soit en tant que tel, envers un autre Utilisateur ou envers
          une personne ou une catégorie de personnes désignées par leur race, leur sexe, leur religion ou toute autre
          caractéristique ;
        </li>
        <li>
          tout harcèlement, notamment du fait de la saturation des commentaires par tentative de publication répétée de
          messages identiques ou très voisins ;
        </li>
        <li>toute promotion commerciale ou publicitaire ;</li>
        <li>tout propos sans rapport avec le débat dans lequel il s’inscrit.</li>
      </ul>
    </div>
    <p>
      CookandBe se réserve en outre le droit (i) d’interrompre l’accès au Site à l’Utilisateur qui contreviendrait aux
      règles de modération précitées et (ii) d’avertir les autorités publiques compétentes.
    </p>

    <h3>ARTICLE 5 – Abonnement aux Services payants – Conditions générales de vente</h3>

    <p><b>5.1 - Commande d’un Service payant</b></p>

    <p>
      L’Utilisateur, sous réserve de son Abonnement préalable, pourra accéder sur le Site à des Services spécifiques
      (ci-après les « Services payants »).
    </p>

    <p>
      L’Utilisateur peut préalablement à sa commande prendre connaissance sur le Site des caractéristiques essentielles
      des Services payants.
    </p>

    <p>
      Le paiement est réalisé conformément aux conditions générales du système de paiement sécurisé
      <i>e-transaction</i> du Crédit Agricole. La transaction est protégée par un cryptage SSL (Secure Socket Layer).
    </p>

    <!--   <p>La validation définitive de la commande par l’Utilisateur s’opère via le bouton de commande
  « Commande. La passation de cette commande vous oblige à son paiement  ».</p>-->

    <p>
      La vente du Service payant ne sera considérée comme définitive qu’après l’envoi à l’Utilisateur (ci-après l’ «
      Abonné ») de la confirmation de l’acceptation de la commande par CookandBe par un email reprenant les informations
      essentielles relatives à la commande, étant précisé que CookandBe est en droit de refuser toute commande d’un
      Utilisateur pour motif légitime. Il en est ainsi notamment en cas de litige relatif au paiement d’une commande
      antérieure ou pour toute commande présentant des conditions anormales et/ou qui ne respecterait pas les termes des
      présentes CGU et/ou CGV.
    </p>

    <p>
      Toute commande passée sur le Site constitue la formation d’un contrat conclu à distance entre l’Abonné et
      CookandBe soumis aux dispositions qui suivent.
    </p>

    <p><b>5.2 - Accès au Service payant</b></p>

    <p>
      L’Abonné pourra accéder au Service payant dans un délai maximum de 2 jours ouvrés à compter de la réception de la
      confirmation de l’acceptation de sa commande par CookandBe.
    </p>
    <p>
      Il est néanmoins possible à l’Abonné d’exiger que l’accès au Service payant ne se fasse qu’à l’expiration d’un
      délai de 14 jours à compter de la confirmation de l’acceptation de sa commande par CookandBe, aux fins de pouvoir
      exercer son droit de rétractation dans les conditions de l’article 5.5.
    </p>
    <p>
      Le Service payant est directement accessible sur le Site pendant toute la durée de l’Abonnement à l’aide des
      identifiants d’Utilisateur de l’Abonné : adresse électronique et mot de passe.
    </p>
    <p>
      CookandBe se réserve le droit d’interrompre l’accès au Service payant en cas de non respect des présentes CGU
      et/ou CGV, et notamment en cas de non paiement, moyennant une mise en demeure de le faire restée sans effet
      pendant 15 jours consécutifs.
    </p>

    <p><b>5.3 - Durée de l’Abonnement</b></p>

    <p>L’Abonné pourra souscrire un Abonnement d’une durée déterminée de un, trois ou douze mois</p>

    <p>Les Abonnements ne seront pas renouvelés par tacite reconduction à l’arrivée de leur terme.</p>

    <p><b>5.4 Tarifs - Conditions de paiement</b></p>

    <p>
      Les Services payants seront facturés sur la base du tarif en vigueur sur le Site au moment de la validation
      définitive de sa commande par l’Abonné. Les prix sont exprimés en Euros, H.T. et T.T.C.
    </p>
    <p>
      Les Services payants sont payables en une fois, au moment de la validation définitive de sa commande par l’Abonné.
    </p>

    <div>
      <p>L’Abonné pourra opter pour l’une des modalités de paiement en ligne suivantes :</p>
      <ul>
        <li>
          paiement par carte bancaire par serveur de paiement sécurisé (Carte bleue, Visa, MasterCard). Le paiement en
          ligne par carte bancaire est assuré par une solution sécurisée qui intègre un procédé de cryptage en mode SSL
          (Secure Socket Layer).
        </li>
      </ul>
    </div>
    <p>
      CookandBe ne conserve aucun numéro de carte bancaire. Ledit numéro est directement transmis à l'opérateur bancaire
      via son système de paiement sécurisé.
    </p>
    <p>
      Les paiements effectués par l’Abonné ne seront considérés comme définitifs qu’après encaissement définitif des
      sommes dues par CookandBe.
    </p>
    <p>
      Les transactions et les informations concernant l’Abonné sont toujours confirmées par email. Il est conseillé à
      l’Abonné d’imprimer l’email de confirmation et de le conserver. Cet email contient en outre toutes les coordonnées
      nécessaires pour contacter CookandBe en cas de problème.
    </p>

    <p><b>5.5 Droit de rétractation</b></p>

    <p>
      L’Abonné dispose d’un délai de rétractation de 14 jours à compter de la réception de la confirmation de
      l’acceptation de sa commande par CookandBe pour exercer son droit de rétractation et annuler sa commande, sans
      avoir à justifier de motifs ni à payer de pénalités, sauf si l’exécution des Services payants a commencé, avec
      l’accord de l’Abonné, avant la fin du délai de rétractation.
    </p>

    <p>
      Le droit de rétractation peut être exercé en ligne, à l’aide du formulaire de rétractation disponible sur le Site.
      En cas d’exercice du droit de rétractation dans le délai susvisé, seul le prix des Services commandés sera
      remboursé.
    </p>

    <p>
      Le remboursement des sommes effectivement réglées par l’Abonné sera effectué dans un délai de 14 jours à compter
      de la réception par CookandBe de la notification de la rétractation de l’Abonné.
    </p>

    <h3>ARTICLE 6 - Responsabilités et garanties</h3>

    <p>
      <b>6.1 -</b> L’Utilisateur reconnait avoir été informé que le Site est optimisé pour les navigateurs récents tels
      que Google Chrome (version 26 minimum), Mozilla Firefox (version 19 minimum), Internet Explorer 9 et 10, Safari 6.
    </p>
    <p>
      L’Utilisateur est seul responsable de l’installation, de l’exploitation et de la maintenance de son matériel
      informatique nécessaire pour accéder au Site. En aucun cas, CookandBe ne saurait être tenue responsable si les
      Services proposés s’avéraient incompatibles ou présentaient des dysfonctionnements avec certains logiciels,
      configurations, systèmes d’exploitation ou équipements de l’Utilisateur.
    </p>
    <p>
      Le Site est par principe accessible 24/24h, 7/7j. L’Utilisateur reconnait néanmoins qu’eu égard aux spécificités
      du réseau Internet, CookandBe ne garantit pas la continuité d’accès et de fonctionnement du Site ou des Services
      s’y rattachant, et ne saurait donc faire l’objet d’une quelconque demande de remboursement ou d’indemnisation en
      cas d’indisponibilité du Site.
    </p>
    <p>
      En outre, CookandBe se réserve la possibilité d’interrompre, de suspendre momentanément ou de modifier sans
      préavis l’accès à tout ou partie du Site afin d’en assurer la maintenance sans que cela puisse donner lieu au
      versement d’une quelconque indemnité à l’Utilisateur.
    </p>
    <p>
      CookandBe décline en conséquence toute responsabilité quant aux difficultés techniques que les Utilisateurs
      pourraient rencontrer sur le Site, quelles qu’en soient la cause, ainsi qu’en cas de dommages causés à leur
      matériel informatique du fait de l’utilisation du Site.
    </p>

    <p>
      <b>6.2 -</b> Les contenus proposés sur le Site sont conformes à la législation française en vigueur. La
      responsabilité de CookandBe ne saurait donc être engagée en cas de non-respect de la législation du pays de
      connexion si celui-ci n’est pas la France.
    </p>

    <p>
      <b>6.3 -</b> L’Utilisateur a parfaitement conscience que le Site n’a qu’une vocation informative et ne saurait en
      aucun cas se substituer à l’avis du corps médical.
    </p>
    <p>
      L’ensemble des renseignements accessibles sur le Site (idées repas, liste de courses, recettes, ...), qu’ils
      s’agissent d’informations d’ordre général ou bien personnalisées en fonction des données communiquées par
      l’Utilisateur le concernant, ne constituent en aucun cas une recommandation de traitement (préventif ou curatif),
      une ordonnance ou un diagnostic, et l’Utilisateur ne pourra prétendre les avoir considérés comme tels.
    </p>
    <p>
      L’utilisation des Services proposés par CookandBe s’effectue donc sous la seule et entière responsabilité,
      contrôle et direction de l’Utilisateur. Il appartient notamment à l’Utilisateur de consulter son médecin
      généraliste et/ou un spécialiste avant de recourir aux Services proposés par CookandBe.
    </p>
    <div>
      <p>
        CookandBe s’engage à fournir à l’Utilisateur ses Services avec toute la prudence et la diligence requises.
        L’Utilisateur admet néanmoins que :
      </p>
      <ul>
        <li>
          CookandBe n’est en aucun responsable du contenu et de la véracité des données communiquées par l’Utilisateur,
          sur la base desquelles seront fournis les Services ;
        </li>
        <li>
          CookandBe ne donne aucune garantie quant à un quelconque résultat (mieux être, perte de poids, qualité des
          recettes…) à la suite de la mise en application des Services proposés sur le Site, que ces derniers l’aient
          été à titre gratuit ou onéreux ;
        </li>
        <li>
          CookandBe ne garantit pas la conformité des Services, en ce compris les recettes, aux besoins propres et
          attentes de l’Utilisateur.
        </li>
      </ul>
    </div>
    <p>
      Par conséquent, l’Utilisateur reconnait expressément que la responsabilité de CookandBe ne pourra être recherchée
      en cas de dommage, quel qu’il soit, résultant de l’utilisation des Services proposés sur le Site.
    </p>

    <p>
      <b>6.4 -</b> Sans préjudice des dispositions qui précèdent, concernant l’Abonnement aux Services payants,
      CookandBe est responsable de plein droit à l’égard de l’Abonné de la bonne exécution des Services objets de
      l’Abonnement. Toutefois, CookandBe peut s’exonérer de tout ou partie de sa responsabilité en apportant la preuve
      que l'inexécution ou la mauvaise exécution des Services est imputable soit à l’Abonné, soit au fait, imprévisible
      et insurmontable, d'un tiers, soit à un cas de force majeure tel que défini par la jurisprudence française.
    </p>

    <h3 id="privacy">ARTICLE 7 - Protection des données à caractère personnel</h3>

    <p>
      <b>7.1 -</b> CookandBe respecte la vie privée de ses Utilisateurs et se conforme strictement aux lois en vigueur
      sur la protection de la vie privée et des libertés individuelles.
    </p>
    <p>
      Dans le cadre de la fourniture des Services, CookandBe est amenée à collecter et traiter des informations
      nominatives à caractère personnel concernant l’Utilisateur.
    </p>
    <p>
      Conformément à la Loi n°78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et aux libertés,
      le traitement de ces données à caractère personnel a fait l’objet d’une déclaration auprès de la Commission
      Nationale Informatique et Liberté (CNIL) sous le numéro 1845219v0
    </p>

    <div>
      <p>
        <b>7.2 -</b> Lors de l’inscription sur le Site, sont demandées à l’Utilisateur des informations d’identification
        relatives à :
      </p>
      <ul>
        <li>son prénom,</li>
        <li>son âge / date de naissance,</li>
        <li>son genre / sexe,</li>
        <li>son adresse électronique,</li>
        <li>un mot de passe,</li>
      </ul>
    </div>
    <p>
      Les informations devant obligatoirement être communiquées par l’Utilisateur aux fins de valider son inscription et
      de bénéficier des fonctionnalités du Site sont identifiées par un astérisque.
    </p>

    <p>
      <b>7.3 -</b> L’Utilisateur reconnait, par ailleurs, que la nature des Services fournis sur le Site, à savoir des
      conseils alimentaires personnalisés, implique que CookandBe ait accès à certaines données ayant trait à ses
      habitudes de vie ou encore à sa santé.
    </p>

    <div>
      <p>
        Pourront ainsi être demandées à l’Utilisateur, dans le cadre de son accès aux Services, des données relatives à
        :
      </p>
      <ul>
        <li>son poids et sa taille,</li>
        <li>son activité physique,</li>
        <li>ses goûts et habitudes alimentaires,</li>
        <li>son emploi du temps,</li>
        <li>son foyer,</li>
        <li>ses équipements électro-ménagers,</li>
        <li>
          ses antécédents médicaux et éventuelles contre-indications (niveau de cholestérol, allergies alimentaires...).
        </li>
      </ul>
    </div>
    <p>
      CookandBe pourrait ainsi être conduite à collecter et traiter des données dites sensibles, au sens de l’article
      8-I de la Loi 78-17 du 6 janvier 1978 modifiée : « données à caractère personnel qui font apparaître, directement
      ou indirectement, les origines raciales ou ethniques, les opinions politiques, philosophiques ou religieuses ou
      l’appartenance syndicale des personnes, ou qui sont relatives à la santé ou à la vie sexuelle de celles-ci ».
    </p>
    <p>
      A titre d’exemple, les données en lien avec le régime alimentaire de l’Utilisateur (« halal », « casher », « sans
      porc »…) pourraient indirectement constituées des informations à caractère religieux.
    </p>
    <p>
      La communication de ces données sensibles demeure facultative et leur collecte ainsi que leur traitement supposent
      en tout état de cause le consentement exprès et préalable de l’Utilisateur. A ce titre, pour toute demande de
      données sensibles, l’Utilisateur sera invité à matérialiser son consentement en cochant la case prévue à cet
      effet.
    </p>
    <p>
      L’Utilisateur est néanmoins informé que l’absence de communication de telles données influera nécessairement sur
      la pertinence et la personnalisation des Services fournis par CookandBe. Dès lors, si l’Utilisateur ne souhaite
      pas consentir à la collecte et au traitement de certaines données le concernant, il reconnait que certains
      Services ne pourront lui être fournis et/ou le seront de manière moins pertinente et personnalisée. Il ne pourra,
      par conséquent, en aucun cas engager la responsabilité de CookandBe à ce titre.
    </p>

    <p>
      <b>7.4 -</b> Les données à caractère personnel des Utilisateurs sont stockées par CookandBe sur ses serveurs, et
      ne sont destinées qu’à permettre aux Utilisateurs d’accéder aux fonctionnalités du Site et des Services.
    </p>
    <p>
      CookandBe s’engage à ne pas utiliser ces données à caractère personnel pour une autre raison et à ne pas les
      communiquer à des tiers, notamment en vue d’une exploitation commerciale, sans le consentement exprès et préalable
      de l’Utilisateur.
    </p>
    <p>
      Sauf avis contraire de l’Utilisateur au moment de son inscription, les données d’identification le concernant
      pourront être utilisées par CookandBe dans le cadre de l’envoi hebdomadaire de newsletters (recettes du moment,
      nouvelles fonctionnalités proposées sur le Site…) et de notifications relatives à son compte personnel.
      L’Utilisateur pourra à tout moment modifier son choix dans la rubrique « Paramètres – Gestion des emails ».
    </p>
    <p>
      CookandBe s’engage à conserver les données personnelles de l’Utilisateur pour la durée strictement nécessaire au
      traitement envisagé et, en toute hypothèse, dans les limites imposées par la loi.
    </p>

    <p>
      <b>7.5 -</b> Conformément à la loi informatique et libertés du 6 janvier 1978, l’Utilisateur dispose d’un droit
      d’accès, de rectification, d’opposition et de suppression sur les données personnelles le concernant. Ce droit
      peut être exercé en écrivant à l’adresse suivante : Service Données personnelles - Cook&amp;Be - 34 rue Ferdinand
      Buisson - 29300 Mellac
    </p>
    <p>Un justificatif d'identité pourra être demandé par voie postale à l'adresse précitée.</p>

    <h3>ARTICLE 8 - Témoins de connexion dits « cookies »</h3>

    <p>
      Le Site utilise la technologie de suivi des cookies, afin notamment de faciliter la navigation de l’Utilisateur
      sur le Site.
    </p>
    <p>
      Un cookie ne permet pas d’identifier l’Utilisateur, mais en revanche, il enregistre des informations relatives à
      la navigation sur le Site (page consultée, date et heure…) qui pourront être directement lues par les serveurs de
      CookandBe lors de visites ultérieures de l’Utilisateur sur le Site.
    </p>
    <p>
      CookandBe informe l’Utilisateur qu’il peut s’opposer à l’enregistrement de tels cookies via les paramètres de
      configuration de son navigateur.
    </p>

    <h3>ARTICLE 9 - Propriété intellectuelle</h3>

    <p>
      Le contenu du Site est la propriété de CookandBe et est protégé par les lois françaises et internationales
      relatives à la propriété intellectuelle.
    </p>
    <p>
      Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un
      délit de contrefaçon.
    </p>
    <p>
      En outre, CookandBe demeure propriétaire de tous les droits de propriété intellectuelle sur les présentations,
      études, articles, recettes, photographies, dessins, modèles, icônes, graphismes, images, vidéos, planning etc.
      réalisés en vue de la fourniture des Services à l’Utilisateur, qu’ils s’agissent de Services fournis à titre
      gratuit ou onéreux. L’Utilisateur s’interdit donc toute reproduction ou exploitation desdites présentations,
      études, photographies, dessins, modèles, planning etc. sans l’autorisation expresse, écrite et préalable de
      CookandBe.
    </p>

    <h3>ARTICLE 10 - Utilisation des liens hypertextes et bannières publicitaires</h3>

    <p>
      CookandBe est en lien avec différents sites partenaires, aux fins de compléter les Services proposés à
      l’Utilisateur. A ce titre, le Site contient des liens hypertextes et/ou des bannières publicitaires vers des sites
      Internet de tiers proposant des informations, opinions et recommandations et/ou fournissant différents services
      et/ou produits.
    </p>
    <p>
      CookandBe ne contrôle pas le contenu de ces sites tiers, qui relève de la seule et entière responsabilité des
      partenaires qui en sont les éditeurs.
    </p>
    <p>
      Les partenaires éditeurs de sites marchands sont seuls responsables des produits et services proposés sur leurs
      sites, et du respect des lois et règlementations applicables à leur activité.
    </p>
    <p>
      L’Utilisateur est soumis, dès lors qu’il accède au site d’un partenaire, aux conditions générales d’utilisation du
      partenaire et, le cas échéant, à ses conditions générales de vente en cas de commande de produits et/ou de
      services.
    </p>
    <p>
      Cookandbe ne pourra par conséquent être tenue pour responsable d’un quelconque dommage subi par l’Utilisateur lié
      à l’utilisation du site du partenaire et/ou à la fourniture de produits et/ou de services via ce site.
    </p>

    <h3>ARTICLE 11 - Droit applicable - Litige</h3>

    <p>
      <b>11.1 -</b> Les présentes CGU et CGV ainsi que tous les actes et opérations qui en découlent sont exclusivement
      régis par le droit français.
    </p>
    <p>
      Les CGU et CGV sont rédigées en langue française. Dans l’hypothèse où elles seraient traduites en une ou plusieurs
      langues étrangères, seul le texte français ferait foi en cas de litige.
    </p>

    <p>
      <b>11.2 -</b> En cas de litige de toute nature ou de contestation relative aux présentes CGU et CGV, à la
      formation, à l’exécution ou à la résiliation des Abonnements, le tribunal compétent sera désigné selon les règles
      de droit commun.
    </p>
    <p>
      L’Abonné est informé qu’il peut en tout état de cause recourir à une médiation conventionnelle ou à tout autre
      mode alternatif de règlement des différends (conciliation par exemple) en cas de contestations relatives à son
      Abonnement aux Services payants.
    </p>
  </div>
</template>

<script>
export default {
  name: "Cgv",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

<template>
  <h2>
    <span class="op-icon-dmd col-12 text-center d-sm-none">
      <FontAwesomeIcon :icon="['fas', 'heart']" />
    </span>
    <span class="d-none d-sm-inline">
      <FontAwesomeIcon :icon="['fas', 'heart']" />
    </span>
    La seule méthode GARANTIE sans carence
  </h2>
  <p>Perdre du poids, c'est bien. Mais faut-il y sacrifier sa santé ?</p>
  <div>
    <p>
      Un
      <a href="https://www.anses.fr/sites/default/files/documents/NUT2009sa0099.pdf" target="_blank"
        >rapport de l'ANSES</a
      >
      dénonce les risques liés aux régimes minceur :
    </p>
    <ul>
      <li>Problèmes osseux, musculaires et rénaux</li>
      <li>Augmentation du risque cardiaque</li>
      <li>Nombreuses carences</li>
    </ul>
  </div>
  <p>
    Avec Cook&amp;Be, grâce au contrôle des apports sur 30 nutriments,
    <b>vous avez la GARANTIE</b> de ne pas faire souffrir votre corps.<br />
  </p>

  <h2>
    <span class="op-icon-dmd col-12 text-center d-sm-none">
      <FontAwesomeIcon :icon="['fas', 'apple-alt']" />
    </span>
    <span class="d-none d-sm-inline">
      <FontAwesomeIcon :icon="['fas', 'apple-alt']" />
    </span>
    Les <i>apports caloriques</i> adaptés à votre organisme
  </h2>
  <p>Avec Cook&amp;Be, pas de "régime 1200kcal" pour tous !</p>
  <div>
    <p>Les apports caloriques diminuent dans le temps :</p>
    <ul>
      <li>Calcul des bons apports en fonction de votre poids/taille/activité sportive</li>
      <li>Dégressivité dans le temps, jusqu'à votre objectif</li>
    </ul>
  </div>
  <p>Votre corps n'est pas agressé, et la perte devient durable.</p>

  <h2>
    <span class="op-icon-dmd col-12 text-center d-sm-none">
      <FontAwesomeIcon :icon="['fas', 'check']" />
    </span>
    <span class="d-none d-sm-inline">
      <FontAwesomeIcon :icon="['fas', 'check']" />
    </span>
    Avec le <i>rééquilibrage automatique</i>, plus de frustration.
  </h2>
  <p>
    Envie d'un plat particulier ? Vous pouvez indiquer ce que vous souhaitez.<br />
    Cook&amp;Be s'occupe de rééquilibrer le reste de votre journée.
  </p>
  <p>
    Nous croyons que la perte de poids ne doit pas être une punition, mais un rééquilibrage en douceur, sans
    frustration.
  </p>
  <p>
    Avec Cook&amp;Be, vous alliez plaisir et minceur.<br />
    Vous ne risquez pas de craquer !
  </p>

  <h2>
    <span class="op-icon-dmd col-12 text-center d-sm-none">
      <FontAwesomeIcon :icon="['fas', 'weight']" />
    </span>
    <span class="d-none d-sm-inline">
      <FontAwesomeIcon :icon="['fas', 'weight']" />
    </span>
    Exclusif ! <i>Contre l’effet Yo-yo</i> : le contrôle calorique dynamique&copy;
  </h2>
  <p>Nos suggestions s'adaptent à l'évolution réelle de votre poids.</p>

  <p>Renseignez-le régulièrement sur Cook&amp;Be, et suivez votre courbe dans le temps.</p>
  <p>
    * Vous ne perdez pas suffisamment ?<br />
    Nous adaptons votre alimentation, jusqu'à ce que vous parveniez à perdre ces kilos en trop !
    <span style="font-size: 10px">(1)</span>
  </p>
  <p>
    * Vous perdez trop vite ?<br />
    Attention : c'est la meilleure façon pour reprendre tout aussi rapidement.<br />
    Cook&amp;Be fait en sorte que votre perte de poids soit durable
  </p>
  <p>
    * Objectif atteint ?<br />
    Conservez votre poids en utilisant notre régime "équilibré", 100% gratuit
  </p>
  <p>
    <span style="font-size: 10px"
      >(1) dans la limite où votre objectif de perte de poids est raisonnable et adapté à votre corps</span
    >
  </p>

  <h2>
    <span class="op-icon-dmd col-12 text-center d-sm-none">
      <FontAwesomeIcon :icon="['fas', 'check']" />
    </span>
    <span class="d-none d-sm-inline">
      <FontAwesomeIcon :icon="['fas', 'check']" />
    </span>

    Recommandé par <i>notre nutritionniste</i>
  </h2>

  <p>
    Nos suggestions s'appuient sur les recommandations officielles des autorités de santé (<b>HAS</b> - Haute Autorité
    de Santé, et <b>ANSES</b> - Agence nationale de sécurité sanitaire de l’alimentation, de l’environnement et du
    travail).
  </p>
  <p>Thierry Poitou, nutritionniste, participe à la configuration des alimentations spécifiques.</p>
</template>

<script>
export default {
  name: "SlimDetails",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

<template>
  <ul>
    <li>Limitation du cholestérol alimentaire et des graisses saturées</li>
    <li>Apports optimaux en acides gras essentiels (omégas 6, omégas 3...)</li>
    <!--   <li>Richesse en fibres végétales </li> -->
    <li>Maîtrise des apports caloriques</li>
  </ul>
</template>

<script>
export default {
  name: "NormolipidicSummary",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

<template>
  <ul>
    <li>Exclusion des aliments et recettes contenant du blé, du seigle, de l'orge ou de l'avoine</li>
    <li>Avertissement pour les aliments à surveiller car pouvant contenir des traces de gluten ou être contaminés</li>
    <li>Contrôle de l'équilibre sur 30 nutriments pour éviter les carences</li>
  </ul>

  <div class="text-center" style="padding-top: 20px">
    <div><b>Soutenu par l'AFDIAG</b>, Association Française Des Intolérants Au Gluten</div>
    <div>
      <a href="http://www.afdiag.fr" target="_blank"><img src="@/assets/img/corp/afdiag.jpg" height="100" /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlutenFreeSummary",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

<template>
  <ul>
    <li>Exclusion des aliments et recettes contenant des protéines animales (viandes, poisson et fruits de mer)</li>
    <li>Contrôle de l'équilibre sur 30 nutriments pour éviter les carences</li>
  </ul>
</template>

<script>
export default {
  name: "VegetarianSummary",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

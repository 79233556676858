<template>
  <ul>
    <li>Des idées repas <b>rapides</b> et <b>équilibrées</b>.</li>
    <li>Des recettes originales, de <b>saison</b>.</li>
    <li>Le respect de vos apports en <b>vitamines et minéraux</b>.</li>
    <li>Des apports caloriques adaptés pour ne pas prendre de poids.</li>
  </ul>
</template>

<script>
export default {
  name: "BalancedSummary",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

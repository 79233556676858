<template>
  <ul>
    <li>Des idées repas avec les bons apports caloriques.</li>
    <li>Une alimentation qui <b>s'adapte à l'évolution de votre poids</b>.</li>
    <li>
      <b>Pas de frustration</b> : indiquez ce que vous souhaitez manger, et bénéficiez du rééquilibrage automatique.
    </li>
    <li>Pour mieux perdre du poids : réduction des lipides, légère augmentation des protéines.</li>
    <li>Maitrise des sucres et des graisses nocives.</li>
    <li><b>Suivi du poids</b> sur un graphique.</li>
  </ul>
</template>

<script>
export default {
  name: "SlimSummary",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

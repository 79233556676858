<template>
  <h2>
    <span class="op-icon-dmd col-12 text-center d-sm-none">
      <FontAwesomeIcon :icon="['fas', 'user']" />
    </span>
    <span class="d-none d-sm-inline me-2">
      <FontAwesomeIcon :icon="['fas', 'user']" />
    </span>
    <i>Rééquilibrage des graisses</i> au profit des acides gras essentiels
  </h2>
  <p>
    Pour diminuer le taux de cholestérol, la clé réside dans le type de graisse consommée.<br />
    Avec de mauvaises graisses, votre corps fabrique du mauvais cholestérol.
  </p>
  <div>
    <p>Cook&amp;Be vous assure une alimentation :</p>
    <ul>
      <li>Enrichie en graisses mono-insaturées</li>
      <li>Enrichie en Oméga 3 (ratio élevé Oméga 3 / Oméga 6)</li>
      <li>Pauvre en graisses saturées</li>
    </ul>
  </div>
  <p>Cook&amp;Be s'occupe de tout, en fonction de vos besoins.</p>

  <h2>
    <span class="op-icon-dmd col-12 text-center d-sm-none">
      <FontAwesomeIcon :icon="['fas', 'apple-alt']" />
    </span>
    <span class="d-none d-sm-inline">
      <FontAwesomeIcon :icon="['fas', 'apple-alt']" />
    </span>
    Contrôle du <i>cholestérol alimentaire</i>
  </h2>
  <p>
    Certains aliments sont riches en cholestérol. Pour la majorité des personnes, l'effet est faible ou nul sur le taux
    de cholestérol sanguin.
  </p>
  <p>
    Mais 1 personne sur trois y est sensible. Par précaution, il vaut donc mieux limiter ces aliments (comme les
    oeufs)<br />
    Cook&amp;Be vous propose des repas qui garantissent un faible niveau journalier de cholestérol alimentaire.
  </p>

  <h2>
    <span class="op-icon-dmd col-12 text-center d-sm-none">
      <FontAwesomeIcon :icon="['fas', 'utensils']" />
    </span>
    <span class="d-none d-sm-inline">
      <FontAwesomeIcon :icon="['fas', 'utensils']" />
    </span>
    Laissez une place au <i>plaisir</i> de manger
  </h2>
  <p>Avec Cook&amp;Be, vous avez le droit de vous faire plaisir !</p>
  <p>Indiquez ce que vous souhaitez manger et Cook&amp;Be rééquilibrera les autres plats de la journée.</p>
  <p>Vous pouvez même inclure vos propres recettes.</p>

  <h2>
    <span class="op-icon-dmd col-12 text-center d-sm-none">
      <FontAwesomeIcon :icon="['fas', 'weight']" />
    </span>
    <span class="d-none d-sm-inline">
      <FontAwesomeIcon :icon="['fas', 'weight']" />
    </span>
    Maitrisez votre poids
  </h2>
  <p>
    Chez les personnes présentant un surpoids, la perte de quelques kilos permet de faire baisser le taux de cholestérol
    .
  </p>
  <p>Si vous êtes en surpoids, Cook&amp;Be vous propose des suggestions adaptées pour une perte de poids en douceur.</p>

  <h2>
    <span class="op-icon-dmd col-12 text-center d-sm-none">
      <FontAwesomeIcon :icon="['fas', 'check']" />
    </span>
    <span class="d-none d-sm-inline">
      <FontAwesomeIcon :icon="['fas', 'check']" />
    </span>
    Recommandé par notre <i>nutritionniste</i>
  </h2>
  <p>
    Nos suggestions s'appuient sur les recommandations officielles des autorités de santé (<b>HAS</b> - Haute Autorité
    de Santé, et <b>ANSES</b> - Agence nationale de sécurité sanitaire de l’alimentation, de l’environnement et du
    travail).
  </p>
  <p>Thierry Poitou, nutritionniste, participe à la configuration des alimentations spécifiques.</p>
</template>

<script>
export default {
  name: "NormolipidicDetails",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

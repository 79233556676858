<template>
  <ul>
    <li>Réduction des apports en lipides</li>
    <li>Exclusion des aliments riches en fibres et/ou à goût fort</li>
    <li>Des repas du soir plus légers que ceux du midi</li>
  </ul>
</template>

<script>
export default {
  name: "EasyDiagestSummary",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="op-uservoice" v-show="shouldShow">
    <div class="btn btn-success bottom-uservoice uservoice-icon" data-uv-trigger>
      <FontAwesomeIcon :icon="['fas', 'bullhorn']" />
      <span>Un avis ? Un soucis ?</span>
    </div>
  </div>
</template>

<script>
import { ENABLE_USERVOICE } from "@/config.js";

export default {
  name: "UserVoice",
  props: ["show"],
  computed: {
    shouldShow() {
      return (
        this.show &&
        ENABLE_USERVOICE &&
        this.$store.getters["user/isLoggedIn"] &&
        this.$route.name !== "PaymentChoice"
      );
    },
  },
  data: () => ({
    ENABLE_USERVOICE,
  }),
};
</script>

<style scoped lang="scss">
$uservoice-button-width: 160px;

@media print {
  .uservoice-icon {
    display: none;
  }
}

.uservoice-icon {
  text-align: center;
  margin-bottom: 10px;
  margin-right: 10px;
  width: $uservoice-button-width;

  svg {
    margin-right: 6px;
    margin-bottom: 0px;
  }
}

.bottom-uservoice {
  @media (min-width: $bootstrap-xs-min) {
    z-index: 100; // Menu level
    position: fixed;
    left: 15px;
    width: 200px;
    bottom: 5px;
  }
}

.op-uservoice {
  @media (max-width: $bootstrap-xxs-max) {
    margin: auto;
    text-align: center;
  }
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { sync } from "vuex-router-sync";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { ENABLE_SENTRY } from "./config";
import {
  faChild,
  faBars,
  faExclamationTriangle,
  faExclamationCircle,
  faQuestionCircle,
  faUser,
  faUserMd,
  faUsers,
  faPowerOff,
  faHome,
  faUtensils,
  faShoppingBasket,
  faShoppingCart,
  faBook,
  faAppleAlt,
  faCog,
  faLock,
  faAt,
  faGlobeEurope,
  faTimes,
  faEnvelope,
  faInbox,
  faInfoCircle,
  faBullhorn,
  faComments,
  faBullseye,
  faCheck,
  faCalendarAlt,
  faChartLine,
  faChartPie,
  faTrophy,
  faCoffee,
  faWrench,
  faBlender,
  faStar,
  faEuroSign,
  faPlus,
  faMinus,
  faMapMarkerAlt,
  faPencilAlt,
  faTrash,
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faCaretRight,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faPrint,
  faBan,
  faBackward,
  faForward,
  faHeart,
  faUpload,
  faSearch,
  faFire,
  faEdit,
  faSync,
  faFlask,
  faMusic,
  faBirthdayCake,
  faWeight,
  faBalanceScale,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSquare,
  faCheckSquare,
  faFrown,
  faThumbsDown,
  faThumbsUp,
  faHandPointRight,
  faClock,
  faLightbulb,
  faHeart as faEmptyHeart,
  faStar as faEmptyStar,
  faCopyright,
  faLemon,
  faHourglass,
} from "@fortawesome/free-regular-svg-icons";
import * as Sentry from "@sentry/vue";

library.add(
  faChild,
  faBars,
  faExclamationTriangle,
  faExclamationCircle,
  faQuestionCircle,
  faUser,
  faUserMd,
  faUsers,
  faPowerOff,
  faHome,
  faUtensils,
  faShoppingBasket,
  faShoppingCart,
  faBook,
  faAppleAlt,
  faCog,
  faLock,
  faAt,
  faGlobeEurope,
  faSquare,
  faCheckSquare,
  faTimes,
  faEnvelope,
  faInbox,
  faInfoCircle,
  faFrown,
  faBullhorn,
  faComments,
  faBullseye,
  faCheck,
  faCalendarAlt,
  faChartLine,
  faChartPie,
  faTrophy,
  faCoffee,
  faThumbsDown,
  faThumbsUp,
  faHandPointRight,
  faWrench,
  faBlender,
  faStar,
  faEmptyStar,
  faClock,
  faEuroSign,
  faPlus,
  faMinus,
  faMapMarkerAlt,
  faPencilAlt,
  faTrash,
  faLightbulb,
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faCaretRight,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faPrint,
  faBan,
  faBackward,
  faForward,
  faHeart,
  faEmptyHeart,
  faCopyright,
  faUpload,
  faSearch,
  faFire,
  faEdit,
  faSync,
  faFlask,
  faMusic,
  faBirthdayCake,
  faLemon,
  faWeight,
  faHourglass,
  faBalanceScale,
  faTachometerAlt
);

const app = createApp(App);

app.component("FontAwesomeIcon", FontAwesomeIcon);

sync(store, router);

if (ENABLE_SENTRY) {
  Sentry.init({
    app,
    dsn: "https://1fa258b08a274b5abe1e62c0a6af4609@o1155303.ingest.sentry.io/6235697",
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.01,
  });
}

app.use(store).use(router).mount("#app");

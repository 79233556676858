<template>
  <ul>
    <li>Les bons apports en sels</li>
    <li>Limitation du cholestérol alimentaire et graisses saturées</li>
    <li>Contrôle des apports en vitamine K</li>
    <li>Maîtrise des apports caloriques</li>
    <li>Contrôle des apports en féculents, produits sucrés et alcool pour lisser la glycémie</li>
  </ul>
</template>

<script>
export default {
  name: "CardiovascularSummary",
  props: [],
  data: () => ({}),
  computed: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>
